.cases-preview {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: var(--default-max-width);
    gap: 40px;
    padding: 80px var(--default-desktop-padding) 120px;

    .title {
        color: var(--color-coal);
        margin: 0;
    }

    .text {
        max-width: 700px;

        p {
            color: var(--color-coal);
            font-size: 16px;
            font-weight: 300;
        }
    }

    @media screen and (max-width: 800px) {
        margin: 0;
        flex-direction: column;
        align-content: space-between;
        gap: 20px;
    }

    @media screen and (max-width: 600px) {
        padding: 50px var(--default-mobile-padding);
    }
}

.teaser-list {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
    gap: 32px;

    & > div {
        flex-basis: 47%;
        min-height: 300px;
    }

    @media screen and (max-width: 1100px) {
        & > div {
            flex-basis: 100%;
            min-height: 450px;
        }
        gap: 16px;
    }
}
