.container {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 80px;
    background-color: rgba(0, 0, 0, 0.7);
    @media screen and (max-width: 1200px) {
        padding: 40px;
    }
    @media screen and (max-width: 600px) {
        padding: var(--default-mobile-padding);
    }
}

.wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1320px;
    text-align: left;
    margin: 0 auto;

    h1 {
        font-family: var(--header-font-family);
        color: var(--color-white);
        text-shadow: 0 0 30px rgba(0, 0, 0, 0.15), 0 0 50px rgb(0,0,0, 0.15);
        font-size: 48px;
        max-width: 904px;
        margin: 0;

        @media screen and (max-width: 600px) {
            font-size: 32px;
        }
    }

    p {
        color: var(--color-white);
        font-size: 28px;
        font-weight: 200;
        max-width: 680px;
        line-height: 36px;
        margin-bottom: 48px;
        margin-top: 32px;

        @media screen and (max-width: 600px) {
            font-size: 18px;
        }
    }

    .buttonwrapper {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        a {
            background-color: var(--color-white);
            color: var(--color-coal);
            font-size: 18px;
            font-weight: 500;
            padding: 12px 20px;
            gap: 10px;
            border-radius: 100px;

            &:hover {
                background: var(--color-coal);
                color: var(--color-white);
            }
        }

        a:last-child {
            border: 2px solid var(--color-white);
            background: transparent;
            color: var(--color-white);

            &:hover {
                color: var(--color-white);
                background: var(--color-coal);
                border: 2px solid var(--color-coal);
            }
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: flex-end;
    }
}
