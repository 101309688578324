.hero-video {
    position: relative;
    background-position: center;
    overflow: hidden;
    font-size: 0;
    height: 100svh;

    video {
        position: absolute;
        width: 100vw;
        object-fit: cover;
        top: 0;
        bottom: 0;
        height: 100%;
        z-index: 0;
    }
}
