.columns-container {
    display: flex;
    flex-wrap: wrap;
    max-width: var(--default-max-width);
    margin: 0 auto;
    justify-content: space-between;

    .column p {
        margin: 16px 0;
    }

    @media screen and (max-width: 600px) {
        & > * {
            flex-basis: 100%;
        }
    }
}
