@import '../Hero/HeroGeneric.module';
@import '../Keyframes';

.background {
    background-color: #fff;
}

.hero {
    height: min(100svh, 1400px);
    background-color: black;

    video {
        height: calc(100% - var(--default-desktop-padding));
        width: 100vw;

        @media screen and (max-width: 1050px) {
            height: 100%;
            position: absolute;
            object-fit: cover;
            top: 0;
        }

        @media screen and (max-width: 600px) {
            object-position: -350px;
        }
    }

    &:after {
        margin-top: var(--default-header-height);
        content: unset;
    }

    @media screen and (max-width: 1050px) {
        height: unset;
    }
}

.overlay {
    height: 100%;
    display: flex;
    justify-content: flex-end;

    .text {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        text-align: center;
        margin: 80px 0;
        text-shadow: 0 0 10px rgb(0, 0, 0, 0.6);

        > * {
            margin: 0;
        }
    }

    .body {
        font-size: 16px;
        line-height: 24px;
    }

    h1.title {
        font-size: 40px;
    }

    @media screen and (max-width: 1050px) {
        position: revert;
    }

    @media screen and (max-width: 600px) {
        padding: 60px var(--default-mobile-padding);
    }
}

.content {
    margin: 160px auto 40px;
    max-width: 1300px;
    padding: 0 var(--default-desktop-padding);

    @media screen and (max-width: 1050px) {
        margin: 40px auto 80px;
    }

    @media screen and (max-width: 600px) {
        padding: 0 var(--default-mobile-padding);
    }
}

.scroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-white);
    text-decoration: none;
    margin-bottom: 8px;
    animation: jiggle 3s infinite;
    font-family: JustAnotherHand, sans-serif;
    font-size: 32px;
}

.hook {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 600px;
    align-content: space-between;
    column-gap: 16px;

    .hook-image {
        max-width: 45%;
        object-fit: contain;
        object-position: top;
    }

    .hook-title {
        max-width: 50%;
        font-family: Muli, sans-serif !important;
        font-weight: bold;
        color: var(--color-black);
        font-size: 36px;
        margin: 0;
        text-transform: uppercase;
    }

    .hook-body {
        max-width: 50%;
        font-weight: 300;
        line-height: 32px;
        margin-bottom: 40px;
    }

    @media screen and (max-width: 900px) {
        align-items: flex-start;
        gap: 8px;
        max-height: unset;
        margin: 40px 0 80px;

        .hook-image {
            order: 3;
            max-width: 100%;
            height: auto;
        }

        .hook-title {
            order: 1;
            max-width: 100%;
            text-align: left ;
        }

        .hook-body {
            order: 2;
            max-width: 100%;
        }

        .hook-button {
            order: 4;
            margin: 24px 0;
        }
    }
}
